// Popup.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { GrUpgrade } from 'react-icons/gr';
import './Popup.css';
import { useAuthStateContext } from '../../contexts/AuthContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

Modal.setAppElement('#root'); // Replace '#root' with the id of your root element if it's different

const PortalConfig = ({ isOpen, onRequestClose, portalId, name }) => {
  const { authAccountDeleted, hubSpotIds, handleDeleteAccount, deletingAccount } =
    useAuthStateContext();

  const userId = sessionStorage.getItem('userId');

  const navigate = useNavigate();

  const deleteAccount = async () => {
    await handleDeleteAccount(userId, portalId);

    // After deletion, find the next available id
    const nextIdIndex = hubSpotIds.indexOf(portalId) + 1;

    if (hubSpotIds[nextIdIndex]) {
      // If there is a next id, navigate to it
      navigate(`/hs-account/${hubSpotIds[nextIdIndex]}`);
    } else if (hubSpotIds.length > 1) {
      // If there's no next id, but there are other ids, navigate to the first one
      navigate(`/hs-account/${hubSpotIds[0]}`);
    } else {
      // If there are no more ids, navigate to the 'No Portal Added' page
      navigate(`/hs-account/no-account-portal`);
    }
  };
  return (
    <Modal
      className="max-w-xl min-h-590 upgrade-popup bg-gray-100 rounded-lg overflow-hidden  mx-auto my-32 text-center relative"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Feature Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0 0 0 / 50%)',
        },
      }}
    >
      <div className="bg-btn1 min-h-[40px] py-[10px] flex justify-center items-center relative">
        <AiOutlineCloseCircle
          onClick={onRequestClose}
          className="absolute text-2xl translate-y-[-50%] top-[50%] right-6 text-white cursor-pointer"
        />
      </div>
      {deletingAccount && <LoadingSpinner />}
      <div className="flex flex-col justify-center items-center">
        <div className=" p-5">
          <h2 className="text-xl font-semibold text-center mb-5">
            <span className="text-sm">Portal Name: </span>
            <br />
            {name}
          </h2>
          <p className="font-semibold text-sm text-red-500 mb-8">
            <span className="text-black dark:text-gray-200">Warning:</span> Clicking the delete
            button below will permanently delete the currently selected HubSpot account and remove
            active plugins. Please ensure that this is your intended action, as this cannot be
            undone. you will need to re-connect your HubSpot account to continue using this app.
          </p>

          <button
            onClick={deleteAccount}
            className="block px-6 text-sm py-1 mx-auto text-red-400 border-solid border-red-500 border-1 rounded-xl hover:shadow-lg transition-all duration-300"
          >
            Delete HubSpot Account
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PortalConfig;
