import React, { useEffect, useRef } from 'react';
import md5 from 'blueimp-md5';
import { MdOutlineCancel } from 'react-icons/md';
import { useOutsideClick } from '../hooks/useOutsideClick';

import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import { useAuthStateContext } from '../contexts/AuthContext';
import baseURL from '../url';

const UserProfile = () => {
  const { currentColor, isClicked, setIsClicked, initialState } = useStateContext();
  const { setIsLoggedIn } = useAuthStateContext();

  const elementRef = useRef(null);

  const userEmail = sessionStorage.getItem('email');
  const username = sessionStorage.getItem('username');

  const email = userEmail;
  const size = 200;
  const myImageRef = useRef(null);

  useOutsideClick(elementRef, () => {
    setIsClicked(initialState);
  });

  const handleLogout = async (e) => {
    setIsClicked(initialState);
    e.preventDefault();
    try {
      const response = await fetch(`${baseURL}/api/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        console.log('Logout successful');
        // clear prevUrl from localStorage

        sessionStorage.clear();
        setIsLoggedIn(false);
      } else {
        console.log('Logout failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (myImageRef.current) {
      myImageRef.current.src = 'http://www.gravatar.com/avatar/' + md5(email) + '.jpg?s=' + size;
    }
  }, [email, size]);

  return (
    <div
      ref={elementRef}
      className="nav-item absolute right-1 shadow-xl top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96"
    >
      <div className="flex justify-end items-center">
        {/* <p className="font-semibold text-lg dark:text-gray-200">User Profile</p> */}
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img className="rounded-full h-24 w-24" id="myimg" ref={myImageRef} alt="User" />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">{username}</p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{userEmail}</p>
          <button>
            <a
              href="https://en.gravatar.com/emails/"
              target="_blank"
              className="text-btn2 hover:brightness-75 transition-all duration-300 text-sm font-semibold"
              rel="noreferrer"
            >
              Change Avatar
            </a>
          </button>
        </div>
      </div>
      <div></div>
      <div className="mt-5">
        <button
          type="button"
          onClick={handleLogout}
          style={{ backgroundColor: currentColor, color: 'white', borderRadius: '10px' }}
          className={`w-full text-[16px]  p-3  hover:drop-shadow-xl`}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
