import React from 'react';
import axios from 'axios';
import { FaPlay } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import baseURL from '../../url';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { AiFillCloseCircle } from 'react-icons/ai';

const ExecuteFeatureButton = ({ userId, hubspotId, featureId, postData, isOnEnable }) => {
  const [isExecuting, setIsExecuting] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [pluginRan, setPluginRan] = React.useState('');

  console.log('isOnEnable: ', isOnEnable);

  const executeFeature = async (e) => {
    setProcessing(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseURL}/api/execute-feature/${userId}/${hubspotId}/${featureId}`,
        postData // passed as the body of the POST request
      );

      if (response.data.status === 'success') {
        setPluginRan(response.data.status);
        setProcessing(false);
      } else {
        setProcessing(false);
        setPluginRan(response.data.status);
      }
    } catch (error) {
      console.error('Error executing feature:', error);
    }
  };

  const executeFeatureWithWarning = async (e) => {
    e.preventDefault();
    executeFeature(e);
  };

  const cancelAction = () => {
    setIsExecuting(false);
    setPluginRan('');
    setProcessing(false);
  };

  const pluginResults = () => {
    return (
      <>
        {pluginRan === 'success' ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-[#ffffffa6]">
            <div className="bg-gray-100 shadow-2xl p-5 rounded-md absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
              <p className=" text-green-500 font-semibold flex gap-2 w-full items-center">
                Plugin executed successfully <BsFillCheckCircleFill />
              </p>
              <button
                onClick={() => cancelAction(false)}
                className=" bg-btn2 text-sm text-white font-semibold mt-5 px-5 py-2 rounded-xl hover:shadow-lg active:shadow-inner transform active:translate-y-[2px] transition-shadow duration-200"
              >
                Ok
              </button>
            </div>
          </div>
        ) : (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-[#ffffffa6]">
            <div className="bg-gray-100 shadow-2xl p-5 rounded-md absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
              <p className=" text-red-500 font-semibold flex gap-2 w-full items-center">
                Oops 😒 Plugin executed unsuccessfully <AiFillCloseCircle />
              </p>
              <button
                onClick={() => cancelAction(false)}
                className=" bg-btn1 text-sm text-white font-semibold mt-5 px-5 py-2 rounded-xl hover:shadow-lg active:shadow-inner transform active:translate-y-[2px] transition-shadow duration-200"
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const warningPopup = () => {
    return (
      <>
        {/* overlays a warning popup */}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-90 z-10"></div>
        <div
          className="absolute z-40 w-full max-w-[90%] bg-white rounded-md py-8 px-5 shadow-md"
          style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          {processing && <LoadingSpinner />}
          {pluginRan && pluginResults()}

          <p className="font-semibold mb-3">
            <span className="text-red-400">Caution!</span> Initiating a Re-run will reformat all
            contacts within your HubSpot portal, including those previously processed.
          </p>
          <p className="font-semibold">
            Please note, the plugin integration is currently active and processing new or updated
            contacts in real-time. To ensure data integrity and efficiency, we recommend avoiding
            unnecessary re-runs. It is safer and more effective to rely on the plugin's real-time
            updates.
          </p>
          <div className="flex gap-5 justify-center mt-5">
            <button
              className="bg-red-500 rounded-xl max-w-fit flex gap-3 items-center py-2 px-8 text-white hover:shadow-lg active:shadow-inner transform active:translate-y-[2px] transition-shadow duration-200"
              onClick={executeFeatureWithWarning}
            >
              <FaPlay className="text-xl" />
              Re-run
            </button>
            <button
              className="bg-btn2 rounded-xl max-w-fit  flex gap-3 items-center py-2 px-8 text-white hover:shadow-lg active:shadow-inner transform active:translate-y-[2px] transition-shadow duration-200"
              onClick={() => setIsExecuting(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isExecuting && warningPopup()}
      <button
        className={` rounded-xl max-w-fit mx-auto mt-5 flex gap-3 items-center py-2 px-8 text-white hover:shadow-lg active:shadow-inner transform active:translate-y-[2px] transition-shadow duration-200 ${
          isOnEnable ? 'bg-red-500' : 'bg-btn2 mb-16'
        }`}
        onClick={() => setIsExecuting(true)}
      >
        <FaPlay className="text-[12px]" />
        {isOnEnable ? 'Run for existing contacts' : 'Re-run Plugin'}
      </button>
    </>
  );
};

export default ExecuteFeatureButton;
