import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';

const OauthComplete = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set a value in LocalStorage to indicate that the OAuth process is complete
    localStorage.setItem('oauth_complete', 'true');
    console.log('localStorage happened on OauthComplete');
    // Redirect to the main app
    // navigate('/');
  }, [navigate]);

  return (
    <div className="h-full flex flex-col items-center justify-center px-10">
      <h1 className="text-6xl mb-5 text-center text-green-400">Done! 😁</h1>
      <h2 className="text-4xl text-center">HubSpot added successfully.</h2>
    </div>
  );
};

export default OauthComplete;
